.bg-banner {
    background-image: url('../images/oengoo-main-bg.jpg'); /* Replace with your image path */
    background-size: cover;
    background-position: center 100%;
    background-repeat: no-repeat;
    
  }

  .single-app-feature {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    text-align: center;
    background-color:white;
    
    border-radius: 10px;
  }
  
  .single-app-feature.has-line .line-bg {
    width: 100%;
    opacity: 1;
  }
  
  .single-app-feature.has-line:hover  {
    opacity: 1;
  }
  
  .single-app-feature .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    background: linear-gradient(to bottom right, #ff6600, #ff3366);
    -webkit-background-clip: text;
    transition: color 0.3s ease-in-out;
  }
   
  .line-bg {
    height: 4px;
    width: 100%;
    background-image: linear-gradient(to right, #ff6600, #ff3366);
    opacity: 1;
    transition: opacity 0.3s, width 0.3s;
  }
  

  .single-app-feature .line-bg {
    height: 4px;
    background: linear-gradient(159deg, #E64900 16.1%, #FD8C57 80.7%);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
    visibility: hidden; /* Initially hide the line */
  }
  
  .single-app-feature.active .line-bg,
  .single-app-feature:hover .line-bg {
    transform: scaleX(1); /* Show the line when hovering or when the card is active */
    visibility: visible; /* Ensure the line is visible */
  }