.gradient-bg {
    background-color: linear-gradient(159deg, #E64900 16.1%, #FD8C57 80.7%);
}

.color-gradient {
    color: linear-gradient(159deg, #E64900 16.1%, #FD8C57 80.7%);
}

.gradient-bg:hover {
    background: linear-gradient(159deg, #E64900 16.1%, #FD8C57 80.7%);
}


.navbar {
    position: absolute;
    transition: background-color 0.3s ease;
}

.navbar.fixed {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
}

.navbar.open {
    background-color: white;
}