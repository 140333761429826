body {
  margin: 0;
  padding: 0;
  font-family: "Manrope";
}

p {
  font-family: "Manrope";
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Space Grotesk";
}

@tailwind base;
@tailwind components;
@tailwind utilities;


